<!-- 活动商品列表页 -->
<template>
    <div class="productList">
        <div class="center-wrap">
            <div class="product-list-box">
                <div v-if="listData && listData.total > 0" class="product-list-box-content">
                    <div class="product-list-box-content-item" v-for="product in listData.data">
                        <v-home-product-item :product="product" @chooseSpecShow="handleChooseSpecShow"></v-home-product-item>
                    </div>
                </div>
                <div class="pro-pagination-box" v-if="listData && listData.total > 0">
                    <el-pagination layout="prev, pager, next" :total="listData.total" :page-size="listData.per_page" :current-page="Number(listData.current_page)" background :prev-text="$t('pro.upper')" :next-text="$t('pro.down')" @current-change="pageChange">
                    </el-pagination>
                </div>
            </div>
        </div>
        <span class="zhanwei">.</span>
        <v-choose-spec v-if="showChooseSpec" @fnCancelChoose="showChooseSpec=false" :product="product" :showChooseSpecType="showChooseSpecType"></v-choose-spec>
    </div>
</template>
<script>
import chooseSpec from '../components/chooseSpec.vue'
import homeProductItem from '../components/homeProductItem.vue'

export default {
    name: 'ProductList',
    data() {
        return {
            showChooseSpecType: null,
            showChooseSpec: false,
            product: null,
            listData: null,
            activity_id: null
        }
    },
    created() {
        var self = this;
        self.activity_id = this.$route.query.activity_id;
        if (self.activity_id) this.httpGetProList(self.activity_id, 1);
    },
    updated: function() {
        window.scrollTo(0, 0);
    },
    methods: {
        handleChooseSpecShow: function(data) {
            this.product = data.product;
            this.showChooseSpecType = data.type;
            this.showChooseSpec = true;
        },
        toProductDetailPage() {
            this.$router.push("/product")
        },
        httpGetProList(id, page) {
            var self = this;
            self.common.httpPost("/api/ajax/getDiscountGoodsList?page=" + page, { goods_discount_id: id })
                .then(ret => {
                    if (ret.code == 1) {
                        self.listData = ret.data;
                    }
                })
        },
        pageChange(page) {
            this.httpGetProList(this.activity_id, page);
        },
    },
    watch: {
        $route(to, from) {
            this.listData = null;
            self.activity_id = this.$route.query.activity_id;
            if (self.activity_id) this.httpGetProList(self.activity_id, 1);

        }
    },
    components: {
        "v-home-product-item": homeProductItem,
        "v-choose-spec": chooseSpec
    }
}
</script>
<style scoped>
.zhanwei {
    opacity: 0;
}

.category-name-box {
    color: #333;
    font-size: 30px;
    font-family: SimHei, PingFangSC-Medium, PingFang SC, sans-serif;
    padding-left: 83px;
    margin-top: 40px;
}

.product-list-box {
    background-color: white;
    padding: 50px 60px;
    margin: 15px 83px;
}

.product-list-box-content {
    width: 1080px;
    display: flex;
    flex-flow: row wrap;
}

.product-list-box-content-item {
    width: 180px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
}

.productList {
    width: 100%;
    background-color: #f7f7f7;
    padding-bottom: 42px;
}

.titlebox {
    height: 40px;
    background: rgba(247, 246, 246, 1);
    padding-left: 83px;
    display: flex;
    align-items: center;
}

.titlebox div {
    display: flex;
    align-items: center;
}

.titlebox img {
    height: 12px;
    width: 12px;
    margin-left: 13px;
    margin-right: 13px;
}

.titlebox p {
    font-size: 16px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    line-height: 22px;
}

.pro-list-box {
    display: flex;
    justify-content: center;
    padding-bottom: 50px;
}

.wares-box {
    display: flex;
    flex-wrap: wrap;
    border-top: 30px;
    width: 1213px;
}

.pro-pagination-box {
    display: flex;
    justify-content: center;
    /* margin: 20px auto 80px; */
}
</style>